<template>
	<DialogTitle
		:class="clsx(className, _localesStore.locales.selected == 'en' ? 'font-semibold' : 'font-medium', 'text-balance text-lg/6 text-zinc-950 sm:text-base/6 dark:text-white')">
		<slot />
	</DialogTitle>
</template>

  <script lang="ts">
	export default { name: 'VDialogTitle' } // name the component
  </script>

  <script lang="ts" setup>
	import { 
		clsx
	} from 'clsx'
	
	import {
		DialogTitle
	} from '@headlessui/vue'

	import { 
		localesStore
	} from '@/store/locales'

	// eslint-disable-next-line
	const props = defineProps({
		className: {
			type: String,
			default: ""
		}
	})

	const _localesStore = localesStore()
</script>