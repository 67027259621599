<template>
	<tw-notification 
		v-if="showNotification" 
		@close="showNotification = false" 
		:title="notificationTitle" 
		:subtitle="notificationSubtitle" 
		:duration="8000" 
		:icon="notificationIcon" />
		
	<div class="grid grid-cols-4 gap-4 lg:gap-6 px-4">
		<div class="col-span-4 md:col-span-2">
			<!-- nick name -->
			<data-display
				v-if="userStore.getContactType == 'Staff'"			
				:label="localesStore.getLabel('10')"
				:value="contact?.fieldData?.name_nick_display || '-' " 
				:loading="loading" />
			<!-- category -->
			<data-display
				v-else			
				:label="localesStore.getLabel('11')"
				:value="contact?.fieldData?.category_display || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-4 md:col-span-2 row-span-2">
			<!-- contact -->
			<data-display
				:label="localesStore.getLabel('194')"
				value-class="whitespace-pre-line"
				:value="address || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-2 md:col-span-1">
			<!-- country -->
			<data-display
				:label="localesStore.getLabel('7')"
				:value="contact?.fieldData?.['contact__COUNTRIES__countryID::name_display'] || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-2 md:col-span-1">
			<!-- area -->
			<data-display
				:label="localesStore.getLabel('17')"
				:value="contact?.fieldData?.['contact__AREAS_COUNTRIES__areaCountryID::area_display'] || '-' " 
				:loading="loading" />
		</div>
		<div
			v-if="userStore.getContactType == 'Staff'" 
			class="col-span-4 md:col-span-2">
			<!-- account email -->
			<data-display
				:label="localesStore.getLabel('110')"
				:value="contact?.fieldData?.['contacts__USER__contactID::email'] || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-2 md:col-span-1 flex items-end">
			<!-- change email -->
			<button
				class="link" 
				@click="openChangeEmailModal()">
				{{ t('changeEmail.title') }}
			</button>
		</div>
		<div class="col-span-2 md:col-span-1 flex items-end">
			<!-- change password -->
			<button 	
				class="link" 
				@click="openChangePasswordModal()">
				{{ t('changePassword.title') }}
			</button>
		</div>

		<tw-modal
			:title="t('changeEmail.title')"
			:breadcrumb="[]"
			modal-max-width="max-w-md"
			modal-min-height="min-h-0"
			ref="changeEmailModal">
			<change-email-form 
				@close-email-modal="(notification) => {displayNotification(notification)}" />
		</tw-modal>

		<tw-modal
			:title="t('changePassword.title')"
			:breadcrumb="[]"
			modal-max-width="max-w-md"
			modal-min-height="min-h-0"
			ref="changePasswordModal">
			<change-password-form
				@close-password-modal="(notification) => {displayNotification(notification)}" />
		</tw-modal>
	</div>
</template>

<script lang="ts">
	export default { name: 'AccountDetails' } // name the component
</script>

<script setup lang="ts">
	// components
	import ChangeEmailForm from './ChangeEmailForm.vue'
	import ChangePasswordForm from './ChangePasswordForm.vue'

	// languages
	import { 
		useI18n
	} from 'vue-i18n'

	// store
	import { 
		localesStore as _localesStore
	} from '@/store/locales'

	import { 
		store as _userStore
	} from '@/store/user'

	// types
	import { 
		Notification
	} from '@/types'

	// vue
	import {
		ref, 
		computed
	} from 'vue'

	const props = defineProps({
		contact: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})
	
	const localesStore = _localesStore()
	const userStore = _userStore()

	const {t} = useI18n()

	const showNotification = ref(false)
	const notificationIcon = ref(0)
	const notificationTitle = ref('')
	const notificationSubtitle = ref('')
	
	function displayNotification(notification: Notification) {
		notificationIcon.value = notification.icon
		notificationTitle.value = notification.title
		notificationSubtitle.value = notification.subtitle
		changeEmailModal.value.open = false
		changePasswordModal.value.open = false
		showNotification.value = true
	}
	
	const address = computed(() => {		
		return props.contact?.fieldData?.address_full_display_form?.replaceAll("\r", "\n")
	})

	const changeEmailModal = ref<{open: boolean}>({open: false})
	const changePasswordModal = ref<{open: boolean}>({open: false})

	function openChangeEmailModal() {
		changeEmailModal.value.open = true
	}
	
	function openChangePasswordModal() {
		changePasswordModal.value.open = true
	}
</script>