import axios from 'axios'

import { 
	router
} from '@/router'

import { 
	store as _userStore
} from '@/store/user'
import { 
	localesStore
} from '@/store/locales'

import type {
	SceneEditorSubmission
} from '@/types'
 
interface User {
	userId: string
	contactId: string
	email: string
	nameFirst: string
	nameMiddle: string
	nameLast: string
	phone: string
	sessionId: string
	isPasswordChangeNeeded: string
	contactType: string
	language: string
	recordId: string
	loggedIn: boolean
	canAccessSceneEditor: number
}

export class AuthClass {

	/**
	 * Sign in
	 * @param {String} email - The username to be signed in
	 * @param {String} password - The password of the username
	 * @return - A promise resolves the user
	 */
	public signIn(
		email: string,
		password: string
	): Promise<{authenticated: boolean, error: string, user: User }> {

		const data = {
			email: email,
			password: password,
		}
		
		return axios.post('login.php', JSON.stringify(data))
			.then(response => {
				if (response.data.loggedIn == true) {
					return {
						authenticated: true,
						error: '',     
						user: response.data
					}
				}
				else {
					return {
						authenticated: false,
						error: response.data.message,
						user: {} as User
					}
				}    
			}, error => {
				console.log(error)
				return {
					authenticated: false,
					error: String(error),
					user: {} as User
				}
			})
	}
	
	/**
	 * Sign out
	 * @
	 * @return - A promise resolved if success
	 */
	public signOut(): Promise<void> {
		const userStore = _userStore()
		
		return axios.post('logout.php')
			.then(() => {
				userStore.user = {
					userId: "",
					contactId: "",
					email: "",
					nameFirst: "",
					nameMiddle: "",
					nameLast: "",
					phone: "",
					sessionId: "",
					isPasswordChangeNeeded: "",
					contactType: "",
					language: "",
					recordId: "",
					loggedIn: false,
					canAccessSceneEditor: 0
				}
				router.push({name: 'Login'})
				delete localStorage.sessionId
				
			}, error => {
				console.log(error)
			})
	}
	
	/**
	 * Change a password for an authenticated user
	 * @param {Object} email - The email of the user
	 * @param {String} oldPassword - the current password
	 * @param {String} newPassword - the requested new password
	 * @return - A promise resolves if success
	 */
	public changePassword(
		email: string,
		oldPassword: string,
		newPassword: string
	): Promise<{success: boolean, messages: {code: string, message: string}[]}> {

		const data = {
			email: email,
			oldPassword: oldPassword,
			newPassword: newPassword
		}
	
		return axios.post(`changePassword.php`, JSON.stringify(data))
			.then(response => {
				if (response.data.messages[0].code !== "0") {
					return {
						success: false,
						messages: response.data.messages
					}
				}
				else {
					return {
						success: true,
						messages: response.data.messages
					}
				}
			}, error => {
				console.log(error)
				return  {
					success: false,
					messages: [{
						message: String(error),
						code: '-1'
					}]
				} 
			})
	}
	
	/**
	 * Initiate a forgot password request
	 * @param {String} email - the username to change password
	 * @return - A promise resolves if success
	 */
	public forgotPassword(
		email: string
	): Promise<{}> {
		const FM_EMAIL_FIELD = 'email' // change this to match FM field name
		const FM_SCRIPT = 'User - Reset Password' // change this to match FM reset password script name
	
		const parameters = JSON.stringify({
			[FM_EMAIL_FIELD]: email
		})
		const postData = {
			fieldData : {
				postData: parameters
			},
			script : FM_SCRIPT,
			'script.param' : parameters
		}
	
		return axios.post(`runScript.php`, postData)
			.then((response) => {
				return response.data
			})
			.catch((e) => {
				if(e.response.data.messages[0].message) {
					// Return FM Error Response
					return e.response.data
				}else{
					// Format Unknown Error Like a FM Error
					return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
				}
			})
	}
	
	/**
	 * Change an email for an authenticated user
	 * @param {Object} email - The email of the user
	 * @param {String} newEmail - the current password
	 * @param {String} password - the requested new password
	 * @return - A promise resolves if success
	 */
	public changeEmail(
		email: string,
		newEmail: string,
		password: string
	): Promise<{success: boolean, messages: {code: string, message: string}[]}>  {

		const data = {
			email: email,
			newEmail: newEmail,
			password: password
		}
	
		return axios.post(`changeEmail.php`, JSON.stringify(data))
			.then(response => {
				if (response.data.messages[0].code !== "0") {
					return {
						success: false,
						messages: response.data.messages
					}
				}
				else {
					return {
						success: true,
						messages: response.data.messages
					}
				}
			}, error => {
				console.log(error)
				return  {
					success: false,
					messages: [{
						message: String(error),
						code: '-1'
					}]
				} 
			})
	}

}

export const Auth = new AuthClass()
