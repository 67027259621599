<template>
	<Draggable>
		<div class="flex items-center gap-3">
			<div
				tabindex="0"
				class="draggable-card w-full flex items-center justify-between cursor-grab shadow-lg h-full bg-white rounded-md border-2 border-gray-200 p-2 px-3 min-h-15 ">
				<div class="text-sm truncate">
					<p 
						class="truncate text-sm font-medium text-gray-800">
						{{ i + 1 }}. {{ scene['job__job_scene__SCENES__sceneID::name_display'] }}
					</p>
					<div v-if="Boolean(scene['job__JOBS_SCENES__jobID::main_scene'])">
						<p 
							v-if="scene['job__job_scene__job_song__SONGS__songID::name_display']?.length"
							class="text-brand-500 font-medium truncate">
							{{ scene['job__job_scene__job_song__SONGS__songID::name_display'] }}
						</p>
						<p
							v-else
							class="text-gray-400">
							{{ t('sceneEditor.missingSong') }}
						</p>
					</div>
				</div>
				<StarIconMini 
					v-if="Boolean(scene['job__JOBS_SCENES__jobID::main_scene'])"
					class="h-4 w-4 text-brand-500" />
			</div>
			<button
				class="text-sm remove-button cursor-default whitespace-nowrap"
				@click="removeScene">
				{{ t('sceneEditor.remove') }}
			</button>
		</div>
	</Draggable>
</template>

<script lang="ts">
	export default { name: 'SceneCard' } // name the component
</script>

<script setup lang="ts">
	// Drag n drop
	import { 
		Draggable 
	} from 'vue3-smooth-dnd'

	// Languages
	import { 
		useI18n
	} from 'vue-i18n'

	// Store
	import {
		useSceneEditorStore
	} from '@/store/sceneEditor'

	// Vue
	import {
		defineProps
	} from 'vue'

	const props = defineProps({
		i: {
			type: Number,
			default: 0
		},
		loading: {
			type: Boolean,
			default: false
		},
		scene: {
			type: Object,
			default: () => {return {}}
		}
	})

	const {t} = useI18n()
	const sceneEditorStore = useSceneEditorStore()

	/**
	 * Removes the selected scene from the scene editor store
	 * @function removeScene
	 */
	function removeScene() {
		sceneEditorStore.sceneEditor.scenes.splice(props.i, 1)
	}

</script>

<style>
 .card-ghost .remove-button {
	opacity: 0;
 }
</style>