import { 
	defineStore
} from 'pinia'
import { 
	useStorage
} from '@vueuse/core'

interface User {
	userId: string
	contactId: string
	email: string
	nameFirst: string
	nameMiddle: string
	nameLast: string
	phone: string
	sessionId: string
	isPasswordChangeNeeded: string
	contactType: string
	language: string
	recordId: string
	loggedIn: boolean
	canAccessSceneEditor: number
}

export const store = defineStore('user', {
	// a function that returns a fresh state
	state: () => ({
        user: useStorage('user', {
			userId: "",
			contactId: "",
			email: "",
			nameFirst: "",
			nameMiddle: "",
			nameLast: "",
			phone: "",
			sessionId: "",
			isPasswordChangeNeeded: "",
			contactType: "",
			language: "",
			recordId: "",
			loggedIn:false,
			canAccessSceneEditor: 0
        } as User) 
	}),
	
	// optional getters
	getters: {
		getContactType: (state) => {
			switch(state.user.contactType) {
				case 'Company/会社': return "Client"
				case 'Business/ビジネス': return "Client"
				case 'Subsidiary/子会社': return "Client"
				case 'Supplier/業者': return "Client"
				case 'Staff/スタッフ': return "Staff"
				case 'Employee/従業員': return "Staff"
			}
		}
	},

	// optional actions
	actions: {
		// setUserData(state :any, payload :any) {
        //     state.user = payload
		// }
	}
})