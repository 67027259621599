<template>
	<div
		:class="clsx(className, 'mt-6')">
		<slot />
	</div>
</template>

  <script lang="ts">
	export default { name: 'VDialogBody' } // name the component
  </script>

  <script lang="ts" setup>
	import { 
		clsx
	} from 'clsx'

	// eslint-disable-next-line
	const props = defineProps({
		className: {
			type: String,
			default: ""
		}
	})
</script>