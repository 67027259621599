<template>
	<div class="flex items-end justify-between gap-4 border-gray-300 border-b pb-4 -mx-4 sm:-mx-6 px-4 sm:px-6 bg-white">
		<div class="block md:hidden">
			{{ t('sceneEditor.modalDescriptionMobile') }}
		</div>
		<div class="hidden md:block">
			{{ t('sceneEditor.modalDescription') }}
		</div>
		<div 
			v-if="scriptError.length"
			class="text-red-500">
			{{ t('sceneEditor.generalError') }}
		</div>
		<div class="flex gap-4 flex-none">
			<tw-button
				@click="openConfirmation('cancel')"
				class="w-full whitespace-nowrap">
				{{ t('sceneEditor.cancel') }}
			</tw-button>
			<tw-button
				@click="openConfirmation('save')"
				class="w-full btn-brand">
				{{ t('sceneEditor.save') }}
			</tw-button>
		</div>
	</div>
	<div class="flex flex-row -mr-6 min-h-full h-full">
		<div class="w-full md:flex-none md:w-96 pt-4 pb-[177px] pr-4 sm:pr-6 h-full min-h-content overflow-y-auto">
			<div class="flex justify-between items-center mb-4">
				<h2 class="section-title">
					{{ t('sceneEditor.sidebarTitle') }}
				</h2>
				<div class="flex items-center justify-end md:hidden">
					<tw-button
						@click="() => {sceneEditorOpen.open = true}"
						class="w-full btn-brand">
						+ {{ t('sceneEditor.addScene') }}
					</tw-button>
				</div>
			</div>

			<!-- Current Scenes Sidebar -->
			<Container
				group-name="col"
				drag-class="card-ghost"
				drop-class="card-ghost-drop"
				drag-handle-selector=".draggable-card"
				@drop="onDropScene($event)"		
				class="space-y-6"	
				:should-accept-drop="() => {return true}">
				<SceneCard
					v-for="(scene, i) in scenes"
					:key="scene?.recordId"
					:i="i"
					:scene="scene" />
			</Container>
		</div>

		<!-- Available Scenes (Desktop) -->
		<div class="bg-gray-100 w-full hidden md:block p-4 sm:px-6 h-full min-h-full overflow-y-scroll">
			<h2 class="section-title">
				{{ t('sceneEditor.availableScenes') }}
			</h2>
			<div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-6 mt-4 max-w-screen-2xl mx-auto pb-[177px]">
				<template
					v-for="(scene, sceneIndex) in availableScenes"
					:key="sceneIndex">
					<Container
						tag="span"
						group-name="results"
						behaviour="copy"
						drag-class="card-ghost"
						drop-class="card-ghost-drop"
						:get-child-payload="() => getSceneIdByIndex(sceneIndex)"
						:should-animate-drop="shouldAnimateDrop"
						@on-drag-start="onDragStart"
						@on-drag-end="onDragEnd">
						<Draggable>
							<AvailableSceneCard 
								:class="['draggable-item  mx-auto', { 'opacity-50': draggingIndex === sceneIndex }]"
								:scene="scene" />
						</Draggable>
					</Container>
				</template>
			</div>
		</div>
	</div>

	<!-- Confirmation Modal -->
	<v-dialog
		block-overlay-close
		:open="confirmationOpen"
		size="md">
		<v-dialog-title>
			{{ confirmationTitle }}
		</v-dialog-title>
		<v-dialog-description>
			{{ confirmationDescription }}
		</v-dialog-description>
		<v-dialog-body>
			<div class="flex items-center justify-end space-x-4">
				<tw-button
					@click="confirmCancel"
					class="w-full ">
					{{ t('sceneEditor.cancel') }}
				</tw-button>
				<tw-button
					@click="confirmSave"
					class="w-full btn-brand">
					{{ t('sceneEditor.confirm') }}
				</tw-button>
			</div>
		</v-dialog-body>
	</v-dialog>

	<!-- Add Scene Modal -->
	<tw-modal
		modal-max-width="max-w-full"
		modal-min-height="h-screen"
		ref="sceneEditorOpen"
		:title="t('sceneEditor.modalTitle')">
		<h2 class="section-title">
			{{ t('sceneEditor.availableScenes') }}
		</h2>
		<div class="space-y-6 mt-4">
			<template
				v-for="scene in availableScenes"
				:key="scene?.recordId">
				<AvailableSceneCard
					@close-modal="() => {sceneEditorOpen.open = false}"
					:scene="scene" />
			</template>
		</div>
	</tw-modal>
</template>

<script lang="ts">
	export default { name: 'SceneEditor' } // name the component
</script>

<script setup lang="ts">
	// Api
	import { 
		Auth
	} from '@/api/Auth'

	// Components
	import SceneCard from './SceneCard.vue'
	import AvailableSceneCard from './AvailableSceneCard.vue'

	// Drag n drop
	import { 
		Container, 
		Draggable 
	} from 'vue3-smooth-dnd'

	// Languages
	import { 
		useI18n
	} from 'vue-i18n'

	// Mutations
	import {
		submitScenes
	} from '@/modules/jobs/mutations'

	// Store
	import {
		useSceneEditorStore
	} from '@/store/sceneEditor'

	// Types
	import type {
		Notification
	} from '@/types'

	// Vue
	import {
		computed,
		ref
	} from 'vue'

	const {t} = useI18n()
	const emit = defineEmits(['closeModal'])
	const sceneEditorStore = useSceneEditorStore()

	const scenes = computed(() => {
		return sceneEditorStore.sceneEditor.scenes
	})

	const availableScenes = computed(() => {
		return sceneEditorStore.sceneEditor.availableScenes
	})

	const confirmationOpen = ref(false)
	const confirmationTitle = ref(t('sceneEditor.confirmationTitleCancel'))
	const confirmationDescription = ref(t('sceneEditor.confirmationDescriptionCancel'))
	const confirmationCommand = ref('cancel')

	/**
	 * Opens the confirmation modal and sets the modal text based on the selected command
	 * @function openConfirmation
	 * @param {string} command - The command being performed (save/cancel)
	 */
	function openConfirmation(command: string) {
		confirmationTitle.value = command === 'save' ? t('sceneEditor.confirmationTitleSave') : t('sceneEditor.confirmationTitleCancel')
		confirmationDescription.value = command === 'save' ? t('sceneEditor.confirmationDescriptionSave') : t('sceneEditor.confirmationDescriptionCancel')
		confirmationCommand.value = command
		confirmationOpen.value = true
	}

	function confirmCancel() {
		confirmationOpen.value = false
	}

	const scriptError = ref('')

	async function confirmSave() {	
		if(confirmationCommand.value === 'save') await saveScenes()
		else emit('closeModal', confirmationCommand.value)
	}

	/**
	 * Submits the scene edits to FM
	 * @function saveScenes
	 */
	async function saveScenes() {
		scriptError.value = ''

		try {
			const jobId = sceneEditorStore.sceneEditor.jobId
			const scenesArray = sceneEditorStore.sceneEditor.scenes
			const scenes = scenesArray?.map((scene, i) => { 
				return { 
					sceneId: scene['job__JOBS_SCENES__jobID::_kf_scenes_id'], 
					jobSceneId: scene['job__JOBS_SCENES__jobID::_kp_jobs_scenes_id'], 
					contactSceneId: scene['job__JOBS_SCENES__jobID::_kf_contacts_scenes_id'],
					sceneNum: i + 1,
					isMainScene: scene['job__JOBS_SCENES__jobID::main_scene']
				}
			})
			const res = await submitScenes(jobId, scenes)
			const scriptResult = JSON.parse(res?.response?.scriptResult)

			if(scriptResult.errorCode !== '0') {
				const error = scriptResult.errorMessage
				throw error
			}

			const notification: Notification = {
				title: t('placeholders.success'),
				subtitle: t('sceneEditor.scenesUpdated'),
				icon: 0
			}

			emit('closeModal', confirmationCommand.value, notification)
		}
		catch(e) {
			console.log(e)
			scriptError.value = String(e)
		}
		finally {
			confirmationOpen.value = false
		}
	}

	const sceneEditorOpen = ref<{open: boolean}>({open: false})

	// #region Drag and Drop

	/**
	 * @function getSceneIdByIndex
	 * @param sceneIndex - The dragged item's scene index
	 */
	function getSceneIdByIndex(sceneIndex: any) {
		const scene = availableScenes.value[sceneIndex]
		return {sceneIndex, scene}
	}

	/**
	 * @function shouldAnimateDrop -  Set dropping animation, called by the target container to which the dragged item will be droppped
	 */
	function shouldAnimateDrop() {
		return false
	}

	const draggingIndex = ref(null)

	function onDragStart({ isSource, payload }) {
		if(!isSource) return
		// payload holds the item being dragged, find the index and apply class
		draggingIndex.value = payload.sceneIndex
	}

	function onDragEnd() {
		// Remove the dragging class when drag ends
		draggingIndex.value = null
	}

	function onDropScene(dropResult: any) {
		const { removedIndex, addedIndex, payload, element } = dropResult

		if(payload?.scene?.recordId?.length) {
			const scene = {
				recordId: payload.scene.recordId,
				"job__job_scene__SCENES__sceneID::name_display": payload.scene['jobs__contacts_scenes__SCENES__sceneID::name_display'],
				"job__JOBS_SCENES__jobID::scene_number": addedIndex + 1,
				"job__JOBS_SCENES__jobID::main_scene": payload.scene['jobs__CONTACTS_SCENES__serviceID::main_scene'],
				"job__JOBS_SCENES__jobID::standard": payload.scene['jobs__CONTACTS_SCENES__serviceID::standard'],
				"job__JOBS_SCENES__jobID::_kf_jobs_id": payload.scene['JOBS::_kp_jobs_id'],
				"job__job_scene__job_song__SONGS__songID::name_display": '',
				"job__JOBS_SCENES__jobID::_kp_jobs_scenes_id": '',
				"job__JOBS_SCENES__jobID::_kf_contacts_scenes_id": payload.scene['jobs__CONTACTS_SCENES__serviceID::_kp_contacts_scenes_id'],
				"job__JOBS_SCENES__jobID::_kf_scenes_id": payload.scene['jobs__CONTACTS_SCENES__serviceID::_kf_scenes_id'],
				modId: payload.scene.modId
			}

			sceneEditorStore.sceneEditor.scenes.splice(addedIndex, 0, scene)
		}
		else {
			onDropCurrentScene(dropResult)
		}
	}

	/**
	 * Updates the scene order within the store
	 * @function onDropCurrentScene
	 * @param dropResult - The drop result event
	 */
	function onDropCurrentScene(dropResult: any) {
		const { removedIndex, addedIndex, payload, element } = dropResult

		// Validate index
		if (removedIndex < 0 || removedIndex >= scenes.value.length || addedIndex < 0 || addedIndex >= scenes.value.length) return

		// Update scene order within in store
		const sceneToMove = scenes.value.splice(removedIndex, 1)[0]
		sceneEditorStore.sceneEditor.scenes.splice(addedIndex, 0, sceneToMove)
	}

	// #endregion

</script>

<style>
.card-ghost {
    transition: transform 0.18s ease;
    transform: rotateZ(5deg)
}

.card-ghost-drop {
    transition: transform 0.18s ease-in-out;
    transform: rotateZ(0deg)
}
</style>