<template>
	<div class="sm:flex justify-between items-center pb-3 mb-10 border-b-2 border-brand-400">
		<tw-breadcrumb
			:crumbs="breadcrumb" />
		<!-- Filter -->
		<div class="flex items-center sm:justify-end space-x-4 sticky top-0 z-30">
			<!-- Date Picker Dropdown Button -->
			<button
				id="dateRangeButton"
				data-dropdown-toggle="dateRangeDropdown"
				data-dropdown-ignore-click-outside-class="datepicker"
				type="button"
				class="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900 mr-4">
				<span>{{ _localesStore.getLabel('361') }}</span>
				<span
					v-show="numDateRangeFiltersSelected > 0"
					class="ml-1.5 bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-900">
					{{ numDateRangeFiltersSelected }}
				</span>				
				<ChevronDownIconMini
					class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
					aria-hidden="true" />
			</button>

			<!-- Date Picker -->
			<div
				id="dateRangeDropdown"
				class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow  w-[500px] dark:bg-gray-700 dark:divide-gray-600">
				<div
					class="p-3"
					aria-labelledby="dateRangeButton">
					<div class="grid grid-cols-3 gap-x-3">
						<div class="border border-t-0 border-l-0 border-b-0 border-r-gray-300 col-span-1">
							<a
								href="#"
								@click="setDateFilters('lastMonth')"
								class="text-gray-900 flex gap-2 items-center px-4 py-2 text-sm font-medium hover:bg-brand-500 hover:text-white">
								{{ t('dateFilter.lastMonth') }}
							</a>
							<a
								href="#"
								@click="setDateFilters('thisMonth')"
								class="text-gray-900 flex gap-2 items-center px-4 py-2 text-sm font-medium hover:bg-brand-500 hover:text-white">
								{{ t('dateFilter.thisMonth') }}
							</a>
							<a
								href="#"
								@click="setDateFilters('nextMonth')"
								class="text-gray-900 flex gap-2 items-center px-4 py-2 text-sm font-medium hover:bg-brand-500 hover:text-white">
								{{ t('dateFilter.nextMonth') }}
							</a>
							<a
								href="#"
								@click="setDateFilters('lastWeek')"
								class="text-gray-900 flex gap-2 items-center px-4 py-2 text-sm font-medium hover:bg-brand-500 hover:text-white">
								{{ t('dateFilter.lastWeek') }}
							</a>
							<a
								href="#"
								@click="setDateFilters('thisWeek')"
								class="text-gray-900 flex gap-2 items-center px-4 py-2 text-sm font-medium hover:bg-brand-500 hover:text-white">
								{{ t('dateFilter.thisWeek') }}
							</a>
							<a
								href="#"
								@click="setDateFilters('nextWeek')"
								class="text-gray-900 flex gap-2 items-center px-4 py-2 text-sm font-medium hover:bg-brand-500 hover:text-white">
								{{ t('dateFilter.nextWeek') }}
							</a>
						</div>
						<div class="col-span-2">
							<div
								date-rangepicker
								datepicker-autohide
								class="flex items-center">
								<div class="relative">
									<div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
										<svg
											class="w-4 h-4 text-gray-500 dark:text-gray-400"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="currentColor"
											viewBox="0 0 20 20">
											<path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
										</svg>
									</div>
									<input
										@focusout="setStartDateFilter"
										id="datepicker-range-start"
										name="start"
										type="text"
										class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
										:placeholder="t('dateFilter.startDatePlaceholder')">
								</div>
								<span class="mx-2 text-gray-500 dark:text-gray-400">to</span>
								<div class="relative">
									<div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
										<svg
											class="w-4 h-4 text-gray-500 dark:text-gray-400"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="currentColor"
											viewBox="0 0 20 20">
											<path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
										</svg>
									</div>
									<input
										@focusout="setEndDateFilter"
										id="datepicker-range-end"
										name="end"
										type="text"
										class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
										:placeholder="t('dateFilter.endDatePlaceholder')">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Planner -->
			<PopoverGroup 
				v-if="isClient"
				class="flex sm:items-baseline sm:space-x-8">
				<Popover
					as="div"
					:id="`desktop-menu-2`"
					class="relative inline-block text-left">							
					<div>
						<PopoverButton class="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
							<span>{{ t('jobsList.plannerFilter') }}</span>
							<span
								v-show="numPlannerFilterOptionsSelected > 0"
								class="ml-1.5 bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-900">
								{{ numPlannerFilterOptionsSelected }}
							</span>				
							<ChevronDownIconMini
								class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
								aria-hidden="true" />
						</PopoverButton>
					</div>

					<transition
						enter-active-class="transition ease-out duration-100"
						enter-from-class="transform opacity-0 scale-95"
						enter-to-class="transform opacity-100 scale-100"
						leave-active-class="transition ease-in duration-75"
						leave-from-class="transform opacity-100 scale-100"
						leave-to-class="transform opacity-0 scale-95">
						<PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right bg-white py-3 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
							<form class="space-y-2 divide-y divide-gray-200">
								<div class="px-3 w-80">
									<input
										:id="`filter-all-${plannerFilter.label}`"	
										type="checkbox"
										:checked="plannerFilterIndeterminate || numPlannerFilterOptionsSelected == plannerFilter.options.length"
										:indeterminate="plannerFilterIndeterminate" 
										@change="(e) => selectAllPlanners(e)"
										class="h-4 w-4 border-gray-300 text-brand-500 focus:ring-brand-500">
									<label		
										:for="`filter-all-${plannerFilter.label}`"										
										class="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"> {{ t('nav.selectAll') }} </label>
								</div>
								<div class="space-y-2 pt-3 px-3">
									<div
										v-for="(option, i) in plannerFilter.options"
										:key="i">
										<input
											:id="`filter-${option.label}-${i}`"
											:name="`${option.label}[]`"
											:value="option.value"
											v-model="option.checked"
											@change="getData(true)"
											type="checkbox"
											class="h-4 w-4 border-gray-300 text-brand-500 focus:ring-brand-500">
										<label
											:for="`filter-${option.label}-${i}`"
											class="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900">{{ option?.label }}</label>
									</div>									
								</div>																
							</form>								
						</PopoverPanel>
					</transition>
				</Popover>				
			</PopoverGroup>


			<!-- Job & Meeting Types -->
			<PopoverGroup class="flex sm:items-baseline sm:space-x-8">
				<Popover
					as="div"
					v-for="(filter, filterIndex) in filters"
					:key="filter.label"
					:id="`desktop-menu-${filterIndex}`"
					class="relative inline-block text-left">							
					<div>
						<PopoverButton class="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
							<span>{{ filter.label }}</span>
							<span
								v-show="numFilterOptionsSelected > 0"
								class="ml-1.5 bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-900">
								{{ numFilterOptionsSelected }}
							</span>				
							<ChevronDownIconMini
								class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
								aria-hidden="true" />
						</PopoverButton>
					</div>

					<transition
						enter-active-class="transition ease-out duration-100"
						enter-from-class="transform opacity-0 scale-95"
						enter-to-class="transform opacity-100 scale-100"
						leave-active-class="transition ease-in duration-75"
						leave-from-class="transform opacity-100 scale-100"
						leave-to-class="transform opacity-0 scale-95">
						<PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right bg-white py-3 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
							<form class="space-y-2 divide-y divide-gray-200">
								<div class="px-3 w-40">
									<input
										:id="`filter-all-${filter.label}`"	
										type="checkbox"
										:checked="filtersIndeterminate || (filterOptionsSelected[0].length === filter.options[0].options.length && filterOptionsSelected[1].length === filter.options[1].options.length) "
										:indeterminate="filtersIndeterminate" 
										@change="(e) => selectAll(e)"
										class="h-4 w-4 border-gray-300 text-brand-500 focus:ring-brand-500">
									<label		
										:for="`filter-all-${filter.label}`"										
										class="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"> {{ t('nav.selectAll') }} </label>
								</div>
								<div class="space-y-2 pt-3 px-3">
									<div
										v-for="(group, i) in filter.options"
										:key="i">
										{{ group.label }}
										<div
											
											v-for="(option, optionIdx) in group.options"
											:key="option?.value"
											class="flex items-center">
											<input
												:id="`filter-${group.label}-${optionIdx}`"
												:name="`${group.label}[]`"
												:value="option.value"
												v-model="filterOptionsSelected[i]"
												@change="getData(true)"
												type="checkbox"
												class="h-4 w-4 border-gray-300 text-brand-500 focus:ring-brand-500">
											<label
												:for="`filter-${group.label}-${optionIdx}`"
												class="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900">{{ option?.label }}</label>
										</div>
									</div>									
								</div>																
							</form>								
						</PopoverPanel>
					</transition>
				</Popover>				
			</PopoverGroup>

			<!-- Sort -->
			<Menu as="div">
				<div>
					<MenuButton class="inline-flex justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-900">
						{{ _localesStore.getLabel('540') }}
						<ChevronDownIcon
							class="-mr-1 h-5 w-5 text-gray-400"
							aria-hidden="true" />
					</MenuButton>
				</div>
				<transition 
					enter-active-class="transition ease-out duration-100"
					enter-from-class="transform opacity-0 scale-95"
					enter-to-class="transform opacity-100 scale-100"
					leave-active-class="transition ease-in duration-75"
					leave-from-class="transform opacity-100 scale-100"
					leave-to-class="transform opacity-0 scale-95">
					<MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
						<div class="py-1">
							<MenuItem v-slot="{ active }">
								<a
									href="#"
									@click="sortDescend"
									:class="[active ? 'text-white bg-brand-500' : 'text-gray-900', 'flex gap-2 items-center px-4 py-2 text-sm font-medium']">
									{{ t('nav.newestFirst') }}
									<CheckIconMini
										v-if="sort == 'descend'"
										class="h-5 w-5" />
								</a>
							</MenuItem>
							<MenuItem v-slot="{ active }">
								<a
									href="#"
									@click="sortAscend"
									:class="[active ? 'text-white bg-brand-500' : 'text-gray-900', 'flex gap-2 items-center px-4 py-2 text-sm font-medium']">
									{{ t('nav.oldestFirst') }}
									<CheckIconMini
										v-if="sort == 'ascend'"
										class="h-5 w-5" />
								</a>
							</MenuItem>
						</div>
					</MenuItems>
				</transition>
			</Menu>
		</div>		
	</div>
	
	<!-- Jobs -->
	<div class="space-y-4">
		<div 
			v-for="(job, i) in jobs"
			:key="i">
			<Job :job="job" />
		</div>
	</div>
	<div
		class="space-y-4"
		:class="{'mt-4': jobs.length}">
		<div 
			v-for="(meeting, i) in meetings"
			:key="i">
			<Meeting :meeting="meeting" />
		</div>
	</div>
	<div v-show="loading">
		<tw-loading />
	</div>
	<button
		v-show="loadMore"
		@click="getData(false)">
		{{ t('nav.loadMore') }}
	</button>
</template>

<script lang="ts">
	export default { name: 'JobsMain' } // name the component
</script>

<script setup lang="ts">

	// #region imports
	import { 
		Auth
	} from '@/api/Auth'
	// api
	import { 
		getJobsByContactId,
		getJobsByContactIdFilter,
		getMeetingsByJobOrContactId
	} from './queries'
	
	import { 
		FindParameters,
		QueryField
	} from '@/types'
	
	// components
	import Job from './components/Job.vue'
	import Meeting from './components/Meeting.vue'

	// Flowbite
	import {
		initFlowbite
	} from 'flowbite'

	// Headless UI
	import {
		Popover,
		PopoverButton,
		PopoverGroup,
		PopoverPanel,
		Menu,
		MenuButton,
		MenuItem,
		MenuItems
	} from '@headlessui/vue'

	// events
	import EventBus from '@/events'

	// store
	import { 
		store as _userStore
	} from '@/store/user'

	import { 
		localesStore
	} from '@/store/locales'
	import {
		store as _valueListStore
	} from '@/store/valueLists'

	// Vue
	import { 
		computed,
		onBeforeUnmount,
		onMounted,
		ref
	} from 'vue'

	import { 
		useI18n
	} from 'vue-i18n'
	
	import {
		useRouter
	} from 'vue-router'
	// #endregion

	const userStore = _userStore()
	const _localesStore = localesStore()
	const valueListStore = _valueListStore()
	const router = useRouter()
	const {t} = useI18n()

	const isClient = ref(userStore.getContactType === 'Client')

	const breadcrumb = computed(() => {
		return [
			{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}
		]
	})

	// #region get data
	const loading = ref(false)
	const jobs = ref<any[]>([])
	const meetings = ref<any[]>([])
	const offset = ref<number>(1)
	const limit = ref<number>(10)
	const foundCount = ref<number>(0)
	const returnedCount = ref<number>(0)
	const loadMore = computed(() => {
		return foundCount.value >= offset.value
	})

	/**
	 * Returns the query to find jobs, related jobs, and meetings for the logged in user
	 * If a client / staff member is on a main job and related job, the related job is omitted since it is found when loading the main job
	 * If a client / staff member is on a related job but not the main job, the main job is ommitted from the results
	 * @yields {array}
	 */
	const query = computed(() => {
		let jobTypes = filterOptionsSelected.value[0] || []
		let meetingTypes = filterOptionsSelected.value[1] || []
		let querySearch = [] as QueryField[]
		const contactId = userStore.user.contactId
		const isStaff = userStore.getContactType === 'Staff'
		const contactIdField = isStaff ? 'job__JOBS_ROLES__jobID::_kf_contacts_id' : '_kf_contacts_id'
		const relatedContactIdField = isStaff ? 'job__jobs__JOBS_ROLES__jobID::_kf_contacts_id' : 'job__JOBS__kfJobsID::_kf_contacts_id' // used to omit related jobs

		if(jobTypes.length || meetingTypes.length) {
			jobTypes.forEach((type: any) => {
				if(plannerFilterOptionsSelected.value.length) {
					plannerFilterOptionsSelected.value.forEach((plannerId: string) => {
						querySearch.push({
							[contactIdField]: contactId,
							'_kf_planners_id': plannerId,
							type: type,
							'date_start': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
						})
						querySearch.push({
							[relatedContactIdField]: contactId,
							"omit": "true"
						})
					})
				}
				else {
					querySearch.push({
						[contactIdField]: contactId,
						type: type,
						'date_start': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
					})
					querySearch.push({
						[relatedContactIdField]: contactId,
						"omit": "true"
					})
				}
			})

			meetingTypes.forEach((type: any) => {
				if(plannerFilterOptionsSelected.value.length) {
					plannerFilterOptionsSelected.value.forEach((plannerId: string) => {
						querySearch.push({
							[contactIdField]: contactId,
							'_kf_planners_id': plannerId,
							"job__FC_CALENDAR_EVENT__jobID::Type": type,
							'date_start': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
						})
					})
				}
				else {
					querySearch.push({
						[contactIdField]: contactId,
						"job__FC_CALENDAR_EVENT__jobID::Type": type,
						'date_start': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
					})
				}
				
			})
		}
		else if(plannerFilterOptionsSelected.value.length) {
			plannerFilterOptionsSelected.value.forEach((plannerId: string) => {
				querySearch.push({
					[contactIdField]: contactId,
					'_kf_planners_id': plannerId,
					'date_start': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
				})
				querySearch.push({
					[relatedContactIdField]: contactId,
					"omit": "true"
				})
			})
		}
		else {
			querySearch = [
				{
					[contactIdField]: contactId,
					'date_start': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
				},
				{
					[relatedContactIdField]: contactId,
					"omit": "true"
				}
			]
		}			

		return querySearch
	})

	const sort = ref<"ascend"|"descend">("descend")
	const jobOptions = ref<{label: string, value: string}[]>(valueListStore.valueList.serviceTypes)
	const meetingOptions = ref<{label: string, value: string}[]>(valueListStore.valueList.meetingTypes)

	onMounted(async () => {
		initFlowbite()
		getData()

		EventBus.on('change-language', async () => {
			await getData(true)
		})
	})

	onBeforeUnmount(() => {
		EventBus.off('change-language', null)
	})

	async function getData(reset: boolean = false) {
		try {
			loading.value = true

			// reset variables if needed
			if(reset) { 
				offset.value = 1
				foundCount.value = 0
				returnedCount.value = 0
				jobs.value.length = 0
				meetings.value.length = 0
			}

			const contactId = userStore.user.contactId
			const sessionId = userStore.user.sessionId	
			const setLanguageScriptParams = JSON.stringify({
				language: _localesStore.getFmLanguage,
				sessionId,
				contactId
			})

			
			if(onlyMeetingsSelected.value) { // only fetch matching meeting records
				const contactId = userStore.user.contactId
				const isStaff = userStore.getContactType === 'Staff'
				const contactIdField = isStaff ? 'fc_calendar_sample_event__ATTENDEES__calendarID::_kf_contacts_id' : 'fc_calendar_sample_event__JOBS__jobID::_kf_contacts_id'
				let params: FindParameters = {
					query: [
						{'StartDate': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''}
					],
					sort: [
						{fieldName: "StartDate", sortOrder: sort.value}
					],
					"script.prerequest": "Set Language",
					"script.prerequest.param": setLanguageScriptParams
				}

				filterOptionsSelected.value[1].forEach((type: any) => {
					if(plannerFilterOptionsSelected.value.length) {
						plannerFilterOptionsSelected.value.forEach((plannerId: string) => {
							params.query.push({
								[contactIdField]: contactId,
								'fc_calendar_sample_event__JOBS__jobID::_kf_planners_id': plannerId,
								"Type": type,
								'StartDate': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
							})
						})
					}
					else {
						params.query.push({
							[contactIdField]: contactId,
							"Type": type,
							'StartDate': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
						})
					}
					
				})
		
				let res = await getMeetingsByJobOrContactId(params)
				const foundMeetings = res?.response?.data?.map((record: any) => {
					const fieldData = record.fieldData
					return {
						EndTime: fieldData.EndTime,
						Id: fieldData._kp_fccalendarevents_id,
						StartDate: fieldData.zctDateFormatted,
						StartTime: fieldData.StartTime,
						Title: fieldData.Title,
						Type_display: fieldData.Type_display,
						recordId: record.recordId
					}
				})

				// if client, also check meetings related to jobs
				
				if(reset) {
					meetings.value = foundMeetings
					jobs.value.length = 0
				}
				else meetings.value = [...meetings.value, ...foundMeetings]

			}
			else if(onlyJobsSelected.value) { // only fetch matching job records
				// find list of jobs
				let params: FindParameters = {
					query: query.value,
					sort: [
						{fieldName: "date_start", sortOrder: sort.value}
					],
					limit: limit.value,
					offset: offset.value,
					"script.prerequest": "Set Language",
					"script.prerequest.param": setLanguageScriptParams
				}

				// fetch jobs by contact id
				let res = await getJobsByContactIdFilter(params)

				if(res?.messages?.[0]?.code != "0") { // fm error
					throw {
						code: res?.messages?.[0]?.code,
						message: res?.messages?.[0]?.message,
					}
				}
				foundCount.value = res.response.dataInfo.foundCount
				returnedCount.value = res.response.dataInfo.returnedCount
				offset.value = offset.value + returnedCount.value

				// shape data
				const foundJobs = await Promise.all(res.response.data.map(async (record: any) => {
					const fieldData = record.fieldData
					const job = {
						_kp_jobs_id: fieldData._kp_jobs_id,
						job_id: fieldData.job_id,
						name: fieldData.name_bride_groom_display,
						type_display: fieldData.type_display,
						service: fieldData['job__CONTACTS_SERVICES__contactServicesID::name_display'],
						category_display: fieldData.category_display,
						style_name_display: fieldData['jobs__contacts_services__services__STYLES__stylesID::name_display'],
						theme_name_display: fieldData['jobs__contacts_services__services__THEMES__themesID::name_display'],
						style_name_display_override: fieldData['job__contact_service__STYLE__styleID::name_display'],
						theme_name_display_override: fieldData['job__contact_service__THEMES__themeID::name_display'],
						date_start: fieldData.zctDateFormatted,
						time_start: fieldData.time_start,
						time_end: fieldData.time_end,
						recordId: record.recordId,
						planner_name: fieldData['job__CONTACTS__plannerID::name_display'],
						all_songs_selected: fieldData.zcnAllSongsSelected,
						song_cutoff_date: fieldData.zcdSongSelectionCutoffDate,
						is_wedding: fieldData.zcnIsWeddingWeb
					}
					
					const portalDataJobs = record.portalData.portal_related_jobs.filter((relatedJob: any) => {
						return filterOptionsSelected.value[0].join("").includes(relatedJob['job__JOBS__jobID::type_display'])
					})

					const relatedJobs = await Promise.all(portalDataJobs.map(async (record: any) => {
						const jobId = record['job__JOBS__jobID::_kp_jobs_id']
						return {
							_kp_jobs_id: jobId,
							job_id: record['job__JOBS__jobID::job_id'],
							name: record['job__JOBS__jobID::name_bride_groom_display'],
							type_display: record['job__JOBS__jobID::type_display'],
							service: record['job__jobs__CONTACTS_SERVICES__contactServicesID::name_display'],
							category_display: record['job__JOBS__jobID::category_display'],
							style_name_display: record['jobs__jobs__contacts_services__services__STYLES__stylesID::name_display'],
							theme_name_display: record['jobs__jobs__contacts_services__services__THEMES__themesID::name_display'],
							style_name_display_override: record['job__jobs__contact_service__STYLE__styleID::name_display'],
							theme_name_display_override: record['job__jobs__contact_service__THEMES__themeID::name_display'],
							date_start: record['job__JOBS__jobID::zctDateFormatted'],
							time_start: record['job__JOBS__jobID::time_start'],
							time_end: record['job__JOBS__jobID::time_end'],
							recordId: record.recordId,
							planner_name: record['job__job__CONTACTS__plannerID::name_display'],
							all_songs_selected: record['job__JOBS__jobID::zcnAllSongsSelected'],
							song_cutoff_date: record['job__JOBS__jobID::zcdSongSelectionCutoffDate'],
							is_wedding: record['job__JOBS__jobID::zcnIsWeddingWeb']
						}
					}))		

					return {
						...job,
						relatedJobs: relatedJobs
					}
				}))

				if(reset) {
					jobs.value = foundJobs
					meetings.value.length = 0
				}
				else jobs.value = [...jobs.value, ...foundJobs]
			}

			else if(jobsAndMeetingsSelected.value) { // filters for jobs and meetings selected
				let querySearch: QueryField[] = []
				let jobTypes = filterOptionsSelected.value[0] || []
				let meetingTypes = filterOptionsSelected.value[1] || []
				const contactId = userStore.user.contactId
				const isStaff = userStore.getContactType === 'Staff'
				// const contactIdField = isStaff ? 'fc_calendar_sample_event__ATTENDEES__calendarID::_kf_contacts_id' : 'fc_calendar_sample_event__JOBS__jobID::_kf_contacts_id'
				let contactIdField = isStaff ? 'job__JOBS_ROLES__jobID::_kf_contacts_id' : '_kf_contacts_id'
				const relatedContactIdField = isStaff ? 'job__jobs__JOBS_ROLES__jobID::_kf_contacts_id' : 'job__JOBS__kfJobsID::_kf_contacts_id' // used to omit related jobs

				

				jobTypes.forEach((jobType: any) => {
					meetingTypes.forEach((meetingType: any) => {
						if(plannerFilterOptionsSelected.value.length) {
							plannerFilterOptionsSelected.value.map((plannerId: string) => {
								querySearch.push({
									[contactIdField]: contactId,
									type: jobType,
									'_kf_planners_id': plannerId,
									'date_start': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
								})
								querySearch.push({
									[relatedContactIdField]: contactId,
									"omit": "true"
								})
							})
							
						}
						else {
							querySearch.push({
								[contactIdField]: contactId,
								type: jobType,
								// "job__FC_CALENDAR_EVENT__jobID::Type": meetingType,
								'date_start': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
							})
							querySearch.push({
								[relatedContactIdField]: contactId,
								"omit": "true"
							})
						}
						
					})
				})

				let params: FindParameters = {
					query: querySearch,
					sort: [
						{fieldName: "date_start", sortOrder: sort.value}
					],
					"script.prerequest": "Set Language",
					"script.prerequest.param": setLanguageScriptParams
				}

				// fetch jobs by contact id
				let res = await getJobsByContactId(params)

				if(res?.messages?.[0]?.code != "0") { // fm error
					throw {
						code: res?.messages?.[0]?.code,
						message: res?.messages?.[0]?.message,
					}
				}
				foundCount.value = res.response.dataInfo.foundCount
				returnedCount.value = res.response.dataInfo.returnedCount
				offset.value = offset.value + returnedCount.value

				// shape data
				const foundJobs = await Promise.all(res.response.data.map(async (record: any) => {
					const fieldData = record.fieldData
					const job = {
						_kp_jobs_id: fieldData._kp_jobs_id,
						job_id: fieldData.job_id,
						name: fieldData.name_bride_groom_display,
						type_display: fieldData.type_display,
						service: fieldData['job__CONTACTS_SERVICES__contactServicesID::name_display'],
						category_display: fieldData.category_display,
						style_name_display: fieldData['jobs__contacts_services__services__STYLES__stylesID::name_display'],
						theme_name_display: fieldData['jobs__contacts_services__services__THEMES__themesID::name_display'],
						style_name_display_override: fieldData['job__contact_service__STYLE__styleID::name_display'],
						theme_name_display_override: fieldData['job__contact_service__THEMES__themeID::name_display'],
						date_start: fieldData.zctDateFormatted,
						time_start: fieldData.time_start,
						time_end: fieldData.time_end,
						recordId: record.recordId,
						planner_name: fieldData['job__CONTACTS__plannerID::name_display'],
						all_songs_selected: fieldData.zcnAllSongsSelected,
						song_cutoff_date: fieldData.zcdSongSelectionCutoffDate,
						is_wedding: fieldData.zcnIsWeddingWeb
					}
					const isStaff = userStore.getContactType === 'Staff'
					const portalDataJobs = record.portalData.portal_related_jobs
					let portalDataMeetings = isStaff ? record.portalData.portal_meetings_staff : record.portalData.portal_meetings
					portalDataMeetings = portalDataMeetings.filter((meeting: any) => { // filter related meetings only to those selected
						return filterOptionsSelected.value[1].join("").includes(meeting['job__FC_CALENDAR_EVENT__jobID::Type_display'] || meeting['job__attendees__FC_CALENDAR_EVENT__gStaffID_calendarID::Type_display'])
					})
					const relatedJobs = await Promise.all(portalDataJobs.map(async (record: any) => {
						
						// get related meetings for related jobs
						const jobId = record['job__JOBS__jobID::_kp_jobs_id']
						const contactId = userStore.user.contactId
						let params: FindParameters = {
							query: [{
								_kf_jobs_id: jobId,
								'StartDate': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
							}],
							sort: [
								{fieldName: "StartDate", sortOrder: "ascend"}
							],
							"script.prerequest": "Set Language",
							"script.prerequest.param": setLanguageScriptParams
						}
						if(isStaff) { // limit meetings to staff that's signed in
							params.query[0] = {
								_kf_jobs_id: jobId,
								'fc_calendar_sample_event__ATTENDEES__calendarID::_kf_contacts_id': contactId,
								'StartDate': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
							}
						}
				
						let res = await getMeetingsByJobOrContactId(params)
						let relatedMeetings = res?.response?.data?.filter((meeting: any) => { // filter related meetings only to those selected
							return filterOptionsSelected.value[1].join("").includes(meeting.fieldData.Type_display)
						})
						relatedMeetings = relatedMeetings?.map((record: any) => {
							const fieldData = record.fieldData
							return {
								EndTime: fieldData.EndTime,
								Id: fieldData._kp_fccalendarevents_id,
								StartDate: fieldData.zctDateFormatted,
								StartTime: fieldData.StartTime,
								Title: fieldData.Title,
								Type_display: fieldData.Type_display,
								recordId: record.recordId
							}
						})
						// end - get related meetings for related jobs
						
						return {
							_kp_jobs_id: jobId,
							job_id: record['job__JOBS__jobID::job_id'],
							name: record['job__JOBS__jobID::name_bride_groom_display'],
							type_display: record['job__JOBS__jobID::type_display'],
							service: record['job__jobs__CONTACTS_SERVICES__contactServicesID::name_display'],
							category_display: record['job__JOBS__jobID::category_display'],
							style_name_display: record['jobs__jobs__contacts_services__services__STYLES__stylesID::name_display'],
							theme_name_display: record['jobs__jobs__contacts_services__services__THEMES__themesID::name_display'],
							style_name_display_override: record['job__jobs__contact_service__STYLE__styleID::name_display'],
							theme_name_display_override: record['job__jobs__contact_service__THEMES__themeID::name_display'],
							date_start: record['job__JOBS__jobID::zctDateFormatted'],
							time_start: record['job__JOBS__jobID::time_start'],
							time_end: record['job__JOBS__jobID::time_end'],
							relatedMeetings: relatedMeetings,
							recordId: record.recordId,
							planner_name: record['job__job__CONTACTS__plannerID::name_display'],
							all_songs_selected: record['job__JOBS__jobID::zcnAllSongsSelected'],
							song_cutoff_date: record['job__JOBS__jobID::zcdSongSelectionCutoffDate'],
							is_wedding: record['job__JOBS__jobID::zcnIsWeddingWeb']
						}
					}))
					const relatedMeetings = portalDataMeetings.map((record: any) => {
						return {
							EndTime: record['job__FC_CALENDAR_EVENT__jobID::EndTime'] || record['job__attendees__FC_CALENDAR_EVENT__gStaffID_calendarID::EndTime'],
							Id: record['job__FC_CALENDAR_EVENT__jobID::_kp_fccalendarevents_id'] || record['job__attendees__FC_CALENDAR_EVENT__gStaffID_calendarID::_kp_fccalendarevents_id'],
							StartDate: record['job__FC_CALENDAR_EVENT__jobID::zctDateFormatted'] || record['job__attendees__FC_CALENDAR_EVENT__gStaffID_calendarID::zctDateFormatted'],
							StartTime: record['job__FC_CALENDAR_EVENT__jobID::StartTime'] || record['job__attendees__FC_CALENDAR_EVENT__gStaffID_calendarID::StartTime'],
							Title: record['job__FC_CALENDAR_EVENT__jobID::Title'] || record['job__attendees__FC_CALENDAR_EVENT__gStaffID_calendarID::Title'],
							Type_display: record['job__FC_CALENDAR_EVENT__jobID::Type_display'] || record['job__attendees__FC_CALENDAR_EVENT__gStaffID_calendarID::Type_display'],
							recordId: record.recordId
						}
					})

					return {
						...job,
						relatedJobs: relatedJobs,
						relatedMeetings: relatedMeetings
					}
				}))

				// find any meetings that aren't part of the related job types selected

				contactIdField = isStaff ? 'fc_calendar_sample_event__ATTENDEES__calendarID::_kf_contacts_id' : 'fc_calendar_sample_event__JOBS__jobID::_kf_contacts_id'
				params = {
					query: [],
					sort: [
						{fieldName: "StartDate", sortOrder: sort.value}
					],
					"script.prerequest": "Set Language",
					"script.prerequest.param": setLanguageScriptParams
				}

				filterOptionsSelected.value[1].forEach((meetingType: any) => {
					if(isStaff) {
						params.query.push({
							[contactIdField]: contactId,
							"_kf_jobs_id": "*",
							"Type": meetingType,
							'StartDate': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
						})
					}
					else {
						params.query.push({
							[contactIdField]: contactId,
							"Type": meetingType,
							'StartDate': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
						})
					}
				})
				filterOptionsSelected.value[0].forEach((jobType: any) => {
					if(isStaff) {
						params.query.push({
							[contactIdField]: contactId,
							'fc_calendar_sample_event__JOBS__jobID::zctListRolesContactID': contactId,
							"fc_calendar_sample_event__JOBS__jobID::type": jobType,
							"omit": "true"
						})
					}
					else {
						params.query.push({
							[contactIdField]: contactId,
							"fc_calendar_sample_event__JOBS__jobID::type": jobType,
							"omit": "true"
						})
					}
				})
		
				res = await getMeetingsByJobOrContactId(params)
				const foundMeetings = res?.response?.data?.map((record: any) => {
					const fieldData = record.fieldData
					return {
						EndTime: fieldData.EndTime,
						Id: fieldData._kp_fccalendarevents_id,
						StartDate: fieldData.zctDateFormatted,
						StartTime: fieldData.StartTime,
						Title: fieldData.Title,
						Type_display: fieldData.Type_display,
						recordId: record.recordId
					}
				})
				
				if(reset) {
					meetings.value = foundMeetings
					jobs.value = foundJobs
				}
				else {
					meetings.value = [...meetings.value, ...foundMeetings]
					jobs.value = [...jobs.value, ...foundJobs]
				}
			}

			else { // fecth job and metting records, no filters selected
				// find list of jobs
				let params: FindParameters = {
					query: query.value,
					sort: [
						{fieldName: "date_start", sortOrder: sort.value}
					],
					limit: limit.value,
					offset: offset.value,
					"script.prerequest": "Set Language",
					"script.prerequest.param": setLanguageScriptParams
				}
		
				// fetch jobs by contact id
				let res = await getJobsByContactId(params)

				if(res?.messages?.[0]?.code != "0") { // fm error
					throw {
						code: res?.messages?.[0]?.code,
						message: res?.messages?.[0]?.message,
					}
				}
				foundCount.value = res.response.dataInfo.foundCount
				returnedCount.value = res.response.dataInfo.returnedCount
				offset.value = offset.value + returnedCount.value
			
				// shape data
				const foundJobs = await Promise.all(res.response.data.map(async (record: any) => {
					const fieldData = record.fieldData
					const job = {
						_kp_jobs_id: fieldData._kp_jobs_id,
						job_id: fieldData.job_id,
						name: fieldData.name_bride_groom_display,
						type_display: fieldData.type_display,
						service:fieldData['job__CONTACTS_SERVICES__contactServicesID::name_display'],
						category_display: fieldData.category_display,
						style_name_display: fieldData['jobs__contacts_services__services__STYLES__stylesID::name_display'],
						theme_name_display: fieldData['jobs__contacts_services__services__THEMES__themesID::name_display'],
						style_name_display_override: fieldData['job__contact_service__STYLE__styleID::name_display'],
						theme_name_display_override: fieldData['job__contact_service__THEMES__themeID::name_display'],
						date_start: fieldData.zctDateFormatted,
						time_start: fieldData.time_start,
						time_end: fieldData.time_end,
						recordId: record.recordId,
						planner_name: fieldData['job__CONTACTS__plannerID::name_display'],
						all_songs_selected: fieldData.zcnAllSongsSelected,
						song_cutoff_date: fieldData.zcdSongSelectionCutoffDate,
						is_wedding: fieldData.zcnIsWeddingWeb
					}
					const isStaff = userStore.getContactType === 'Staff'
					const portalDataJobs = record.portalData.portal_related_jobs
					const portalDataMeetings = isStaff ? record.portalData.portal_meetings_staff : record.portalData.portal_meetings
					const relatedJobs = await Promise.all(portalDataJobs.map(async (record: any) => {
						
						// get related meetings for related jobs
						const jobId = record['job__JOBS__jobID::_kp_jobs_id']
						const contactId = userStore.user.contactId
						let params: FindParameters = {
							query: [{
								_kf_jobs_id: jobId,
								'StartDate': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
							}],
							sort: [
								{fieldName: "StartDate", sortOrder: "ascend"}
							],
							"script.prerequest": "Set Language",
							"script.prerequest.param": setLanguageScriptParams
						}
						if(isStaff) { // limit meetings to staff that's signed in
							params.query[0] = {
								_kf_jobs_id: jobId,
								'fc_calendar_sample_event__ATTENDEES__calendarID::_kf_contacts_id': contactId,
								'StartDate': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
							}
						}
				
						let res = await getMeetingsByJobOrContactId(params)

						const relatedMeetings = res?.response?.data?.map((record: any) => {
							const fieldData = record.fieldData
							return {
								EndTime: fieldData.EndTime,
								Id: fieldData._kp_fccalendarevents_id,
								StartDate: fieldData.zctDateFormatted,
								StartTime: fieldData.StartTime,
								Title: fieldData.Title,
								Type_display: fieldData.Type_display,
								recordId: record.recordId
							}
						})
						// end - get related meetings for related jobs
						
						return {
							_kp_jobs_id: jobId,
							job_id: record['job__JOBS__jobID::job_id'],
							name: record['job__JOBS__jobID::name_bride_groom_display'],
							type_display: record['job__JOBS__jobID::type_display'],
							service: record['job__jobs__CONTACTS_SERVICES__contactServicesID::name_display'],
							category_display: record['job__JOBS__jobID::category_display'],
							style_name_display: record['jobs__jobs__contacts_services__services__STYLES__stylesID::name_display'],
							theme_name_display: record['jobs__jobs__contacts_services__services__THEMES__themesID::name_display'],
							style_name_display_override: record['job__jobs__contact_service__STYLE__styleID::name_display'],
							theme_name_display_override: record['job__jobs__contact_service__THEMES__themeID::name_display'],
							date_start: record['job__JOBS__jobID::zctDateFormatted'],
							time_start: record['job__JOBS__jobID::time_start'],
							time_end: record['job__JOBS__jobID::time_end'],
							relatedMeetings: relatedMeetings,
							recordId: record.recordId,
							planner_name: record['job__job__CONTACTS__plannerID::name_display'],
							all_songs_selected: record['job__JOBS__jobID::zcnAllSongsSelected'],
							song_cutoff_date: record['job__JOBS__jobID::zcdSongSelectionCutoffDate'],
							is_wedding: record['job__JOBS__jobID::zcnIsWeddingWeb']
						}
					}))
					const relatedMeetings = portalDataMeetings.map((record: any) => {
						return {
							EndTime: record['job__FC_CALENDAR_EVENT__jobID::EndTime'] || record['job__attendees__FC_CALENDAR_EVENT__gStaffID_calendarID::EndTime'],
							Id: record['job__FC_CALENDAR_EVENT__jobID::_kp_fccalendarevents_id'] || record['job__attendees__FC_CALENDAR_EVENT__gStaffID_calendarID::_kp_fccalendarevents_id'],
							StartDate: record['job__FC_CALENDAR_EVENT__jobID::zctDateFormatted'] || record['job__attendees__FC_CALENDAR_EVENT__gStaffID_calendarID::zctDateFormatted'],
							StartTime: record['job__FC_CALENDAR_EVENT__jobID::StartTime'] || record['job__attendees__FC_CALENDAR_EVENT__gStaffID_calendarID::StartTime'],
							Title: record['job__FC_CALENDAR_EVENT__jobID::Title'] || record['job__attendees__FC_CALENDAR_EVENT__gStaffID_calendarID::Title'],
							Type_display: record['job__FC_CALENDAR_EVENT__jobID::Type_display'] || record['job__attendees__FC_CALENDAR_EVENT__gStaffID_calendarID::Type_display'],
							recordId: record.recordId
						}
					})
		

					return {
						...job,
						relatedJobs: relatedJobs,
						relatedMeetings: relatedMeetings
					}
				}))

				// find any meetings that a staff isn't part of it's related job's roles
				const isStaff = userStore.getContactType === 'Staff'
				if(isStaff) {
					const contactIdField = 'fc_calendar_sample_event__ATTENDEES__calendarID::_kf_contacts_id'
					params = {
						query: [
							{
								[contactIdField]: contactId,
								"_kf_jobs_id": "*",
								'StartDate': startDate.value || endDate.value ? `${startDate.value ?? ''}...${endDate.value ?? ''}` : ''
							},
							{
								[contactIdField]: contactId,
								'fc_calendar_sample_event__JOBS__jobID::zctListRolesContactID': contactId,
								"omit": "true"
							}
						],
						sort: [
							{fieldName: "StartDate", sortOrder: sort.value}
						],
						"script.prerequest": "Set Language",
						"script.prerequest.param": setLanguageScriptParams
					}					
			
					res = await getMeetingsByJobOrContactId(params)
					const foundMeetings = res?.response?.data?.map((record: any) => {
						const fieldData = record.fieldData
						return {
							EndTime: fieldData.EndTime,
							Id: fieldData._kp_fccalendarevents_id,
							StartDate: fieldData.zctDateFormatted,
							StartTime: fieldData.StartTime,
							Title: fieldData.Title,
							Type_display: fieldData.Type_display,
							recordId: record.recordId
						}
					})

					if(reset) {
						jobs.value = foundJobs
						meetings.value = foundMeetings
					}
					else {
						jobs.value = [...jobs.value, ...foundJobs]
						meetings.value = [...meetings.value, ...foundMeetings]
					}
					
				}
				else {
					if(reset) {
						jobs.value = foundJobs
						meetings.value.length = 0
					}
					else jobs.value = [...jobs.value, ...foundJobs]
				}
			}
		}
		catch(e: any) {
			console.log(e)

			// redirect if server session expired 
			if(e?.code == "952") {
				await Auth.signOut()
				router.push({name: "Login"})
			}
		}
		finally {
			loading.value = false
		}
	}
	// #endregion

	// #region sorting

	function sortAscend() {
		sort.value = "ascend"
		getData(true)
	}

	function sortDescend() {
		sort.value = "descend"
		getData(true)
	}

	// #endregion

	// #region filtering
	const startDate = ref('')
	const endDate = ref('')

	const numDateRangeFiltersSelected = computed(() => {
		if(startDate.value && endDate.value) return 2
		else if(startDate.value || endDate.value) return 1
		return 0
	})

	/**
	 * Apply the start date and end date values to match the selected date range
	 * @function setDateFilters
	 * @param {string} dateRange - String value representing the date range selection to be set
	 */
	async function setDateFilters(dateRange: string) {
		const currentDate = new Date()
		const currentYear = currentDate.getFullYear()
		const dateStart = document?.getElementById('datepicker-range-start') as HTMLInputElement
		const dateEnd = document?.getElementById('datepicker-range-end') as HTMLInputElement

		switch(dateRange) {
		case dateRange = 'lastMonth': {
			const lastDay = new Date(currentDate.setDate(0))
			const month = lastDay.getMonth() + 1
			const firstDay = new Date(`${month}/01/${ month == 12 ? currentYear - 1 : currentYear}`)
			startDate.value = firstDay.toLocaleDateString('en-US', {month: 'numeric', day: '2-digit', year: 'numeric'})
			endDate.value = lastDay.toLocaleDateString('en-US', {month: 'numeric', day: '2-digit', year: 'numeric'})
			break
		}
		case dateRange = 'thisMonth': {
			const year = currentDate.getFullYear()
			const month = currentDate.getMonth()
			const firstDay = new Date(year, month, 1)
			const lastDay = new Date(year, month + 1, 0)
			startDate.value = firstDay.toLocaleDateString('en-US', {month: 'numeric', day: '2-digit', year: 'numeric'})
			endDate.value = lastDay.toLocaleDateString('en-US', {month: 'numeric', day: '2-digit', year: 'numeric'})
			break
		}
		case dateRange = 'nextMonth': {
			const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1) 
			const firstDay = new Date(nextMonth)
			const lastDay = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0)
			startDate.value = firstDay.toLocaleDateString('en-US', {month: 'numeric', day: '2-digit', year: 'numeric'})
			endDate.value = lastDay.toLocaleDateString('en-US', {month: 'numeric', day: '2-digit', year: 'numeric'})
			break
		}
		case dateRange = 'lastWeek': {
			const currentDay = currentDate.getDay()
			const firstDay = new Date(currentDate)
			// Subtract (currentDay + 7) to go back to the previous week's Sunday
			firstDay.setDate(currentDate.getDate() - currentDay - 7) 
			const lastDay = new Date(firstDay)
			// Add 6 days to get to Saturday
			lastDay.setDate(firstDay.getDate() + 6) 
			startDate.value = firstDay.toLocaleDateString('en-US', {month: 'numeric', day: '2-digit', year: 'numeric'})
			endDate.value = lastDay.toLocaleDateString('en-US', {month: 'numeric', day: '2-digit', year: 'numeric'})
			break
		}
		case dateRange = 'thisWeek': {
			const firstDay = new Date(currentDate)
			firstDay.setDate(currentDate.getDate() - currentDate.getDay())
			const lastDay = new Date(firstDay)
			lastDay.setDate(firstDay.getDate() + 6)
			startDate.value = firstDay.toLocaleDateString('en-US', {month: 'numeric', day: '2-digit', year: 'numeric'})
			endDate.value = lastDay.toLocaleDateString('en-US', {month: 'numeric', day: '2-digit', year: 'numeric'})
			break
		}
		case dateRange = 'nextWeek': {
			const firstDay = new Date(currentDate)
			firstDay.setDate(currentDate.getDate() - currentDate.getDay() + 7)
			const lastDay = new Date(firstDay)
			lastDay.setDate(firstDay.getDate() + 6)
			startDate.value = firstDay.toLocaleDateString('en-US', {month: 'numeric', day: '2-digit', year: 'numeric'})
			endDate.value = lastDay.toLocaleDateString('en-US', {month: 'numeric', day: '2-digit', year: 'numeric'})
			break
		}
		}

		dateStart.value = startDate.value ?? ""
		dateEnd.value = endDate.value ?? ""

		await getData(true)
	}

	/**
	 * Apply the start date to the calendar selection
	 * @function setStartDateFilter
	 */
	async function setStartDateFilter() {
		const startDateVal = (document?.getElementById('datepicker-range-start') as HTMLInputElement)?.value
		const valueChanged = startDateVal !== startDate.value
		startDate.value = startDateVal
		if(valueChanged) await getData(true)
	}

	/**
	 * Apply the end date to the calendar selection
	 * @function setEndDateFilter
	 */
	async function setEndDateFilter() {
		const endDateVal = (document?.getElementById('datepicker-range-end') as HTMLInputElement)?.value
		const valueChanged = endDateVal !== endDate.value
		endDate.value = endDateVal
		if(valueChanged) await getData(true)
	}

	const filters = computed(() => {
		return [
			{
				label: t('jobsList.filters'), // Jobs & Meeting Types
				field: '',
				options: [
					{label: _localesStore.getLabel('823'), options: jobOptions.value},
					{label: _localesStore.getLabel('825'), options: meetingOptions.value}
				]
			}
		]
	})

	const plannerOptions = ref<{label: string, value: string, checked: boolean}[]>(valueListStore.valueList.planners)

	const plannerFilter = ref({
		label: t('jobsList.plannerFilter'), // Planner
		field: '',
		options: plannerOptions.value
	})

	const onlyMeetingsSelected = computed(() => {
		return filterOptionsSelected.value[0].length === 0 && filterOptionsSelected.value[1].length > 0
	})

	const onlyJobsSelected = computed(() => {
		return filterOptionsSelected.value[0].length > 0 && filterOptionsSelected.value[1].length === 0
	})

	const jobsAndMeetingsSelected = computed(() => {
		return filterOptionsSelected.value[0].length && filterOptionsSelected.value[1].length > 0
	})

	/**
	 * The value of options selected for each filter
	 * @yields {array}
	 */
	const filterOptionsSelected = ref(filters.value?.[0]?.options?.map(() => {return [] as string[]}) || [[] as string[],[] as string[]])

	const plannerFilterOptionsSelected = computed(() => {
		return 	plannerFilter.value.options.filter(option => {
			if(option.checked) return option
		}).map(selectedOption => {
			return selectedOption.value
		})
	})

	/**
	 * The number of options selected for each filter
	 * @yields {array}
	 */
	const numFilterOptionsSelected = computed(() => {
		return [...filterOptionsSelected.value[0], ...filterOptionsSelected.value[1]].length
	})

	const numPlannerFilterOptionsSelected = computed(() => {
		// return 	plannerFilter.value.options.filter(option => {
		// 	if(option.checked) return option
		// }).length

		return plannerFilterOptionsSelected.value.length
	})

	/**
	 * If the selected options for each filter is mixed checked/unchecked
	 * @yields {array}
	 */
	const filtersIndeterminate = computed(() => {
		let numOptions = filters.value[0].options[0].options.length + filters.value[0].options[1].options.length

		return numFilterOptionsSelected.value > 0 && numFilterOptionsSelected.value < numOptions
	})

	const plannerFilterIndeterminate = computed(() => {
		let numOptions = plannerFilter.value.options.length

		return numPlannerFilterOptionsSelected.value > 0 && numPlannerFilterOptionsSelected.value < numOptions
	})

	/**
	 * Runs filter function specified in the fitlers prop after selecting/deselecting all
	 * @function selectAll
	 * @param {Object} event - The event being fired
	 * @param {Number} filterIndex - The index of the filter being selected
	 */
	function selectAll(event: any) {
		filterOptionsSelected.value.forEach((element: any, i: any) => {
			if(event.target.checked) {
				filterOptionsSelected.value[i] = filters.value[0].options[i].options.map((o: {value: string,label: string}) => o.value)
			}
			else{
				filterOptionsSelected.value[i] = []
			}
		})
		getData(true)
	}
	
	/**
	 * Runs filter function specified in the fitlers prop after selecting/deselecting all
	 * @function selectAllPlanners
	 * @param {Object} event - The event being fired
	 */
	function selectAllPlanners(event: any) {
		if(event.target.checked) {
			plannerFilter.value.options.map(option => {
				option.checked = true
			})
		}
		else{
			plannerFilter.value.options.map(option => {
				option.checked = false
			})
		}

		getData(true)
	}
	// #endregion

</script>