<template>
	<div class="flex justify-between gap-4 min-h-15 md:max-w-[295px]">
		<div
			tabindex="0"
			class="shadow-lg cursor-grab bg-white rounded-md border-2 border-gray-200 flex items-center p-2 px-3 justify-between w-full">
			<div class="text-sm truncate mr-3">
				<p class="truncate text-sm font-medium text-gray-800">
					{{ scene['jobs__contacts_scenes__SCENES__sceneID::name_display'] }}
				</p>
			</div>
			<StarIconMini 
				v-if="Boolean(scene['jobs__CONTACTS_SCENES__serviceID::main_scene'])"
				class="h-4 w-4 text-brand-500" />
		</div>
		<tw-button
			@click="addScene"
			class="flex-none btn-brand md:hidden">
			+ {{ t('sceneEditor.add') }}
		</tw-button>
	</div>
</template>

<script lang="ts">
	export default { name: 'AvailableSceneCard' } // name the component
</script>

<script setup lang="ts">
	// Languages
	import { 
		useI18n
	} from 'vue-i18n'

	// Store
	import {
		useSceneEditorStore
	} from '@/store/sceneEditor'

	// Vue
	import {
		defineProps
	} from 'vue'

	const props = defineProps({
		loading: {
			type: Boolean,
			default: false
		},
		scene: {
			type: Object,
			default: () => {return {}}
		}
	})

	const emit = defineEmits(['closeModal'])

	const {t} = useI18n()
	const sceneEditorStore = useSceneEditorStore()

	/**
	 * Adds scene to scene editor store and closes modal
	 * @function addScene
	 */
	function addScene() {
		const scene = {
			recordId: props.scene.recordId,
			"job__job_scene__SCENES__sceneID::name_display": props.scene['jobs__contacts_scenes__SCENES__sceneID::name_display'],
			"job__JOBS_SCENES__jobID::scene_number": sceneEditorStore.sceneEditor.scenes.length,
			"job__JOBS_SCENES__jobID::main_scene": props.scene['jobs__CONTACTS_SCENES__serviceID::main_scene'],
			"job__JOBS_SCENES__jobID::standard": props.scene['jobs__CONTACTS_SCENES__serviceID::standard'],
			"job__JOBS_SCENES__jobID::_kf_jobs_id": props.scene['JOBS::_kp_jobs_id'],
			"job__job_scene__job_song__SONGS__songID::name_display": '',
			"job__JOBS_SCENES__jobID::_kp_jobs_scenes_id": '',
			"job__JOBS_SCENES__jobID::_kf_contacts_scenes_id": props.scene['jobs__CONTACTS_SCENES__serviceID::_kp_contacts_scenes_id'],
			"job__JOBS_SCENES__jobID::_kf_scenes_id": props.scene['jobs__CONTACTS_SCENES__serviceID::_kf_scenes_id'],
			modId: props.scene.modId
		}

		sceneEditorStore.sceneEditor.scenes.push(scene)
		emit('closeModal')
	}

</script>