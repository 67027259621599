import type {
	SceneEditor,
	SceneEditorAvailableScene,
	SceneEditorScene
} from '@/types'
import { 
	defineStore
} from 'pinia'
import { 
	useStorage
} from '@vueuse/core'

export const useSceneEditorStore = defineStore('sceneEditor', {
	// a function that returns a fresh state
	state: () => ({
        sceneEditor: useStorage('sceneEditor', {
			availableScenes: [] as SceneEditorAvailableScene[],
			jobId: '',
			scenes: [] as SceneEditorScene[]
        } as SceneEditor) 
	}),
	
	// optional getters
	getters: {

	},

	// optional actions
	actions: {

	}
})