<template>
	<div
		:class="clsx(className, 'mt-8 flex flex-col-reverse items-center justify-end gap-3 *:w-full sm:flex-row sm:*:w-auto')">
		<slot />
	</div>
</template>

  <script lang="ts">
	export default { name: 'VDialogActions' } // name the component
  </script>

  <script lang="ts" setup>
	import { 
		clsx
	} from 'clsx'

	// eslint-disable-next-line
	const props = defineProps({
		className: {
			type: String,
			default: ""
		}
	})
</script>