<template>
	<component
		:is="layout">
		<router-view :key="route.fullPath" />
	</component>
	<PageFooter class="flex flex-col" />
</template>

<script setup lang="ts">
	// api
	import {
		getMultilinguals,
		getValueLists
	} from './queries'

	// router
	import {
		useRoute
	} from 'vue-router'

	// store
	import { 
		localesStore
	} from '@/store/locales'

	// store
	import { 
		store as _userStore
	} from '@/store/user'
	import { 
		store as _valueListStore
	} from '@/store/valueLists'

	// type
	import { 
		ValueList
	} from '@/types'


	// vue
	import {
		computed,
		onMounted,
		ref
	} from 'vue'
	
	import MainLayout from './layouts/MainLayout.vue'
	import PageFooter from '@/components/PageFooter.vue'

	const route = useRoute()
	const store = localesStore()
	const valueListStore = _valueListStore()
	const userStore = _userStore()

	const layout = computed(() => {
		return route.meta.layout || MainLayout
	})
	const loading = ref(true)

	// load translations from FM
	onMounted(async () => {
		document.addEventListener("touchend", removeClasses, false)

		try {
			if(!userStore.user.loggedIn) {
				return
			}
			
			loading.value = true
			// fetch translations
			let res = await getMultilinguals()
			let vlRes = await getValueLists(userStore.user.contactId)

			vlRes.response.valueLists.forEach((list: ValueList) => {
				let lowerName = list.name.toLowerCase()
				let key = lowerName.split("_").reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)))
				const indexToRemove = list.values.findIndex(v => v.value === "-")
				if(indexToRemove > -1) list.values.splice(indexToRemove, 1)
				valueListStore.valueList[key] = list.values.map((option) => {					
					return {
						value: option.value,
						label: option.displayValue,
						checked: false
					}					
				})
			})

			store.locales.en = JSON.parse("{" + res.response.data[0].fieldData.zsJsonEn.replace(/\r/g, ',') + "}")
			store.locales.ja = JSON.parse("{" + res.response.data[0].fieldData.zsJsonKanji.replace(/\r/g, ',') + "}")
			store.locales.kana = JSON.parse("{" + res.response.data[0].fieldData.zsJsonKana.replace(/\r/g, ',') + "}")
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}
	})

	/**
	 * Removes any drag-n-drop classes to re-enable scrolling
	 * @function removeClasses
	 */
	function removeClasses() {
		document.body.className = ''
	}
</script>