<template>
	<TransitionRoot
		as="template"
		:show="open">
		<Dialog
			as="div"
			class="relative z-50"
			:initial-focus="dialogPanelRef"
			@close="onClose()">
			<TransitionChild
				as="template"
				enter="ease-out duration-100"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="ease-in duration-100"
				leave-from="opacity-100"
				leave-to="opacity-0">
				<div class="fixed inset-0 flex w-screen justify-center overflow-y-auto bg-zinc-950/25 px-2 py-2 focus:outline-0 sm:px-6 sm:py-8 lg:px-8 lg:py-16 dark:bg-zinc-950/50" />
			</TransitionChild>
  
			<div class="fixed inset-0 w-screen overflow-y-auto pt-6 sm:pt-0">
				<div class="grid min-h-full grid-rows-[1fr_auto] justify-items-center sm:grid-rows-[1fr_auto_3fr] sm:p-4">
					<TransitionChild
						as="template"
						enter="ease-out duration-100"
						enter-from="opacity-0 translate-y-12 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-100"
						leave-from="opacity-100 translate-y-0"
						leave-to="opacity-0 translate-y-12 sm:translate-y-0">
						<DialogPanel
							ref="dialogPanelRef" 
							:class="clsx(
								className,
								sizes[size],
								'row-start-2 w-full min-w-0 rounded-t-3xl bg-white p-[--gutter] shadow-lg [--gutter:theme(spacing.8)] sm:mb-auto sm:rounded-2xl dark:bg-zinc-900 forced-colors:outline')">
							<slot />
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

  <script lang="ts">
	export default { name: 'VDialog' } // name the component
  </script>

  <script lang="ts" setup>
	import { 
		clsx
	} from 'clsx'
	
	import {
		Dialog,
		DialogPanel,
		TransitionChild,
		TransitionRoot
	} from '@headlessui/vue'

	import {
		ref
	} from 'vue'

	// eslint-disable-next-line
	const props = defineProps({
		className: {
			type: String,
			default: ""
		},
		onClose:{
			type: Function,
			default: () => {return}
		},
		open: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: "lg"
		}
	})

	const dialogPanelRef = ref(null)

	const sizes: {[k: string]: string} = {
		'xs': 'sm:max-w-xs',
		'sm': 'sm:max-w-sm',
		'md': 'sm:max-w-md',
		'lg': 'sm:max-w-lg',
		'xl': 'sm:max-w-xl',
		'2xl': 'sm:max-w-2xl',
		'3xl': 'sm:max-w-3xl',
		'4xl': 'sm:max-w-4xl',
		'5xl': 'sm:max-w-5xl',
	}
</script>