<template>
	<dt 
		class="text-sm font-medium whitespace-nowrap"
		:class="[labelClass, _localesStore.locales.selected == 'en' ? 'text-gray-500' : 'text-black']">
		{{ props.label }}
	</dt>
	<div v-show="!loading">
		<dd
			v-if="$slots.custom"
			class="truncate w-full">
			<slot name="custom" />
		</dd>
		<dd
			v-else-if="!editing"
			:title="placeholderValue"
			:class="[valueClass, props.value === null || props.value === undefined || props.value === '' ? 'text-gray-300' : 'text-gray-800']"
			class="text-sm font-medium truncate w-full">
			{{ placeholderValue }}
		</dd>
		<div v-else>
			<slot />
		</div>
	</div>
	
	<div v-show="loading">
		<div class="mt-2 h-4 rounded-full bg-gray-300 animate-pulse w-full" />
	</div>
</template>

<script setup lang="ts">

	import { 
		localesStore
	} from '@/store/locales'


	import {
		computed
	} from "vue"

	const placeholderValue = computed(() => {
		let value: string|number = props.value ?? props.placeholder
		if((!value && (!(value === 0))) || (String(value).includes("undefined"))) {
			return null
		}
		else{
			return value
		}
		
	})

	const _localesStore = localesStore()
	
	const props = defineProps({ 
		label: {
			type: String,
			default: ''
		},
		loading: {
			type: Boolean,
			default: false
		},
		editing: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: '—'
		},
		value: {
			type: [ String, Number ],
			default: null
		},
		valueClass: {
			type: String,
			default: ''
		},
		labelClass: {
			type: String,
			default: ''
		},
		badge: {
			type: Boolean,
			default: false
		}
	})
	
</script>
