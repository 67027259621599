<template>
	<div class="w-full bg-gray-50">
		<div class="max-w-screen-2xl mx-auto px-4 sm:px-6 w-full md:px-8">
			<footer class="flex sticky justify-between bottom-0 flex-col md:flex-row w-full md:items-center border-t-2 border-brand-500 ">
				<div class="flex flex-row justify-center md:justify-start w-full mt-2 gap-8">
					<a
						href="#"
						class="link"
						@click="() => {$i18n.locale = 'en'; setLanguage('en')}">
						English
					</a>
					<a
						href="#"
						class="link"
						@click="() => {$i18n.locale = 'ja'; setLanguage('ja')}">
						日本語 (漢字)
					</a>
					<a
						href="#"
						class="link"
						@click="() => {$i18n.locale = 'kana'; setLanguage('kana')}">
						日本語 (仮名)
					</a>
				</div>
				<div class="flex justify-center text-center md:text-left md:justify-end w-full mt-2 md:mr-4 text-xs flex-col md:flex-row sm:space-x-8 sm:py-4">
					<div class="flex-none">
						<p>
							〒461-0001
						</p>
						<p>
							愛知県名古屋市東区泉1丁目12番25号
						</p>
						<p>
							シティタワー名古屋久屋大通公園 609号
						</p>
					</div>
					<div class="flex-none">
						<p>
							{{ currentYear }} Heartful Inc.
						</p>
						<p>
							Tel: 052-962-4778 | Fax: 052-962-4775
						</p>
						<p>
							Email: info@crystalwedding.jp
						</p>
					</div>
				</div>
			</footer>
		</div>
	</div>
</template>

<script lang="ts">
	// name the component
	export default { name: "PageFooter" }
</script>

<script setup lang="ts">
	// store
	import { 
		localesStore
	} from '@/store/locales'

	import { 
		LangTypes
	} from '@/types' // must be imported since file contains an export statement

	import EventBus from '@/events'

	const store = localesStore()
	const currentYear = new Date().getFullYear()

	function setLanguage(lang: LangTypes) {
		store.locales.selected = lang

		// emit global event to reload data in new language
		EventBus.trigger('change-language', null)
	}
</script>