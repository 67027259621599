<template>
	<span 
		:class="[
			`${colors}`, 
			small ? 'px-1.5 py-0.5' : 'px-2 py-1',
			pill ? 'rounded-full' : 'rounded-md',
			'inline-flex items-center text-xs font-medium'
		]">
		<svg
			v-if="dot"
			:class="[dotColor, 'h-1.5 w-1.5 mr-1.5']"
			viewBox="0 0 6 6"
			aria-hidden="true">
			<circle
				cx="3"
				cy="3"
				r="3" />
		</svg>	
		<slot />
		<button
			v-if="removable"
			type="button"
			:class="[removeBg, 'group relative -mr-1 ml-0.5 h-3.5 w-3.5 rounded-sm']"
			@click="removeFunction()">
			<span class="sr-only">Remove</span>
			<svg
				viewBox="0 0 14 14"
				:class="[removeStroke, 'h-3.5 w-3.5']">
				<path d="M4 4l6 6m0-6l-6 6" />
			</svg>
			<span class="absolute -inset-1" />
		</button>
	</span>
</template>

<script setup lang="ts">
	import {
		defineProps,
		onMounted,
		ref
	} from 'vue'

	// eslint-disable-next-line
	const props = defineProps({
		border: {
			type: Boolean,
			default: true
		},
		color: {
			type: String,
			default: 'indigo'
		},
		dot: {
			type: Boolean,
			default: false
		},
		pill: {
			type: Boolean,
			default: false
		},
		removable: {
			type: Boolean,
			default: false
		},
		removeFunction: {
			type: Function,
			default: () => {}
		},
		small: {
			type: Boolean,
			default: false
		}
	})

	const colors = ref('')
	const dotColor = ref('')
	const removeBg = ref('')
	const removeStroke = ref('')

	onMounted(() => {
		switch(props.color) {
		case 'gray':
			colors.value = 'bg-gray-100 text-gray-700 dark:bg-gray-400/10 dark:text-gray-400'
			dotColor.value = 'fill-gray-400 dark:fill-gray-400'
			removeBg.value = 'hover:bg-gray-500/20 dark:hover:bg-gray-400/20'
			removeStroke.value = 'stroke-gray-700/50 group-hover:stroke-gray-700/75 dark:stroke-gray-400/50 dark:group-hover:stroke-gray-400/75'
			break
		case 'red':
			colors.value = 'bg-red-100 text-red-700 dark:bg-red-400/10 dark:text-red-400'
			dotColor.value = 'fill-red-500 dark:fill-red-400'
			removeBg.value = 'hover:bg-red-600/20 dark:hover:bg-red-400/20'
			removeStroke.value = 'stroke-red-700/50 group-hover:stroke-red-700/75 dark:stroke-red-400/50 dark:group-hover:stroke-red-400/75'
			break
		case 'yellow':
			colors.value = 'bg-yellow-100 text-yellow-800 dark:bg-yellow-400/10 dark:text-yellow-500'
			dotColor.value = 'fill-yellow-500 dark:fill-yellow-400'
			removeBg.value = 'hover:bg-yellow-600/20 dark:hover:bg-red-400/20'
			removeStroke.value = 'stroke-yellow-800/50 group-hover:stroke-yellow-800/75 dark:stroke-yellow-400/50 dark:group-hover:stroke-yellow-400/75'
			break
		case 'green':
			colors.value = 'bg-green-100 text-green-700 dark:bg-green-500/10 dark:text-green-400'
			dotColor.value = 'fill-green-500 dark:fill-green-400'
			removeBg.value = 'hover:bg-green-600/20 dark:hover:bg-green-400/20'
			removeStroke.value = 'stroke-green-800/50 group-hover:stroke-green-800/75 dark:stroke-green-400/50 dark:group-hover:stroke-green-400/75'
			break
		case 'blue':
			colors.value = 'bg-blue-100 text-blue-700 dark:bg-blue-400/10 dark:text-blue-400'
			dotColor.value = 'fill-blue-500 dark:fill-blue-400'
			removeBg.value = 'hover:bg-blue-600/20 dark:hover:bg-blue-400/20'
			removeStroke.value = 'stroke-blue-800/50 group-hover:stroke-blue-800/75 dark:stroke-blue-400/50 dark:group-hover:stroke-blue-400/75'
			break
		case 'indigo':
			colors.value = 'bg-indigo-100 text-indigo-700 dark:bg-indigo-400/10 dark:text-indigo-400'
			dotColor.value = 'fill-indigo-500 dark:fill-indigo-400'
			removeBg.value = 'hover:bg-indigo-600/20 dark:hover:bg-indigo-400/20'
			removeStroke.value = 'stroke-indigo-700/50 group-hover:stroke-indigo-700/75 dark:stroke-indigo-400/50 dark:group-hover:stroke-indigo-400/75'
			break
		case 'purple':
			colors.value = 'bg-purple-100 text-purple-700 dark:bg-purple-400/10 dark:text-purple-400'
			dotColor.value = 'fill-purple-500 dark:fill-purple-400'
			removeBg.value = 'hover:bg-purple-600/20 dark:hover:bg-purple-400/20'
			removeStroke.value = 'stroke-violet-700/50 group-hover:stroke-violet-700/75 dark:stroke-purple-400/50 dark:group-hover:stroke-purple-400/75'
			break
		case 'pink':
			colors.value = 'bg-pink-100 text-pink-700 dark:bg-pink-400/10 dark:text-pink-400'
			dotColor.value = 'fill-pink-500 dark:fill-pink-400'
			removeBg.value = 'hover:bg-pink-600/20 dark:hover:bg-pink-400/20'
			removeStroke.value = 'stroke-pink-800/50 group-hover:stroke-pink-800/75 dark:stroke-pink-400/50 dark:group-hover:stroke-pink-400/75'
			break
		default:
			colors.value = 'bg-gray-100 text-gray-700 dark:bg-gray-400/10 dark:text-gray-400'
			dotColor.value = 'fill-gray-400 dark:fill-gray-400'
			removeBg.value = 'hover:bg-gray-500/20 dark:hover:bg-gray-400/20'
			removeStroke.value = 'stroke-gray-700/50 group-hover:stroke-gray-700/75 dark:stroke-gray-400/50 dark:group-hover:stroke-gray-400/75'
			break
		}
	})

</script>