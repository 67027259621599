<template>
	<DialogDescription
		data-slot="text"
		:class="clsx(className, 'mt-2 text-pretty', 'text-base/6 text-zinc-500 sm:text-sm/6 dark:text-zinc-400')">
		<slot />
	</DialogDescription>
</template>

  <script lang="ts">
	export default { name: 'VDialogDescription' } // name the component
  </script>

  <script lang="ts" setup>
	import { 
		clsx
	} from 'clsx'
	
	import {
		DialogDescription
	} from '@headlessui/vue'

	// eslint-disable-next-line
	const props = defineProps({
		className: {
			type: String,
			default: ""
		}
	})
</script>