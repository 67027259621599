// Axios
import axios from 'axios'

// Store
import { 
	localesStore
} from '@/store/locales'

// Types
import type {
	SceneEditorSubmission
} from '@/types'

 function enterRoleTime(params: any) {
	const postData = {
		fieldData : {
			postData: params
		},
		script : "Job Role - Enter Time",
		'script.param' : params
	}
	return axios.post(`runScript.php`, postData)
		.then((response) => {
			return response.data
		})
		.catch((e) => {
			if(e.response.data.messages[0].message) {
				// Return FM Error Response
				return e.response.data
			}else{
				// Format Unknown Error Like a FM Error
				return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
			}
		})
}

/**
 * Submits the edited scenes and updates the job record within FM
 * @function submitScenes
 * @param {string} jobId - The FM id for the job being updated
 * @param {SceneEditorSubmission[]} scenes - The edited scenes array
*/
function submitScenes(jobId: string, scenes: SceneEditorSubmission[]) {

	const _localesStore = localesStore()
	const parameters = JSON.stringify({
		jobId,
		scenes,
		language: _localesStore.getFmLanguage
	})
	const postData = {
		fieldData : {
			postData: parameters
		},
		script : 'Scene - Web - Submit Scene Selection',
		'script.param' : parameters
	}
		
	return axios.post(`runScript.php`, postData)
		.then((response) => {
			return response.data
		})
		.catch((e) => {
			if(e.response.data.messages[0].message) {
				// Return FM Error Response
				return e.response.data
			}else{
				// Format Unknown Error Like a FM Error
				return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
			}
		})
}

export {
	enterRoleTime,
	submitScenes
}