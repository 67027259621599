<template>
	<div class="grid grid-cols-4 gap-4 -mb-4">
		<div :class="[contactType == 'Staff' ? 'col-span-4 lg:col-span-3' : 'col-span-4 lg:col-span-2', ' w-full -mt-10 overflow-x-scroll border-b lg:border-b-0']">
			<tw-table
				:placeholder="t('placeholders.noRoles')"
				:data="groupedRoles"
				:loading="loading">
				<tw-table-column>
					<template #default="{row}">
						{{ row['contact__PHONES__contactID::phone_contact'] || row['contact__EMAILS__contactID::email_contact'] }}
					</template>
				</tw-table-column>
				<tw-table-column>
					<template #default="{row}">
						{{ `${row['contact__PHONES__contactID::extension'] ? 'x' + row['contact__PHONES__contactID::extension'] : ''}` }}
					</template>
				</tw-table-column>
				<!-- type -->
				<tw-table-column 
					:header="localesStore.getLabel('1')">
					<template #default="{row}">
						{{ row['contact__PHONES__contactID::type_display'] || row['contact__EMAILS__contactID::type_display'] }}
					</template>
				</tw-table-column>
				<!-- primary -->
				<tw-table-column 
					:header="localesStore.getLabel('292')">
					<template #default="{row}">
						<CheckIconMini
							v-if="row['contact__PHONES__contactID::primary'] ||
								row['contact__EMAILS__contactID::primary']"
							class="h-5 w-5 text-black" />
						<MinusIconMini
							v-else
							class="h-5 w-5 text-gray-300" />
					</template>
				</tw-table-column>
			</tw-table>
		</div>
		<div :class="[contactType == 'Staff' ? 'col-span-4 lg:col-span-1' : 'col-span-4 lg:col-span-2', 'pb-4']">
			<div :class="[contactType == 'Staff' ? 'grid-flow-row' : 'grid-flow-row lg:grid-flow-col', 'grid grid-cols-4 gap-4 lg:gap-6 px-4']">
				<div :class="[contactType == 'Staff' ? 'col-span-2 lg:col-span-4 row-span-1' : 'col-span-2 lg:col-span-1 row-span-1']">
					<!-- line -->
					<data-display
						:label="localesStore.getLabel('14')"
						:value="contact?.fieldData?.line || '-' " 
						:loading="loading" />
				</div>
				<div :class="[contactType == 'Staff' ? 'col-span-2 lg:col-span-4 row-span-1' : 'col-span-2 lg:col-span-1 row-span-1']">
					<!-- facebook -->
					<data-display
						:label="localesStore.getLabel('15')"
						:value="contact?.fieldData?.facebook || '-' " 
						:loading="loading" />
				</div>
				<div
					:class="[contactType == 'Staff' ? 'col-span-2 lg:col-span-4 row-span-1' : 'col-span-2 lg:col-span-1 row-span-1']">
					<!-- website -->
					<data-display
						:label="localesStore.getLabel('16')"
						:value="contact?.fieldData?.url || '-' " 
						:loading="loading" />
				</div>

				<div 
					v-if="contactType == 'Client'"
					class="row-span-3 col-span-4 lg:col-span-3 space-y-3">
					<!-- delivery options -->
					<data-display						
						:label="localesStore.getLabel('383')">
						<template #custom>
							<div class="grid grid-cols-2 grid-rows-4 grid-flow-col gap-4">
								<div
									v-for="option in deliveryOptions"
									:key="option.label"
									class="flex justify-start col-span-1 row-span-1  gap-2 items-center"
									:class="{'opacity-30': !option.checked}">
									<CheckIconMini
										v-if="option.checked"
										class="h-5 w-5" />
									<MinusIconMini
										v-else
										class="h-5 w-5" />
									<span class="font-medium">{{ option.label }}</span>
								</div>
							</div>
						</template>
					</data-display>
				</div>			
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'ContactInformation' } // name the component
</script>

<script setup lang="ts">
	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore as _localesStore
	} from '@/store/locales'

	import { // vue
		computed
	} from 'vue'
	
	const props = defineProps({
		contact: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		},
		contactType: {
			type: String,
			default: ""
		}
	})

	const localesStore = _localesStore()

	const {t} = useI18n()

	const deliveryOptions = computed(() => {
		let options = [
			{label: localesStore.getLabel('110'), checked: false}, // Email
			{label: localesStore.getLabel('796'), checked: false}, // Mail
			{label: localesStore.getLabel('733'), checked: false}, // Fax
			{label: localesStore.getLabel('797'), checked: false}, // Delivery
			{label: localesStore.getLabel('382'), checked: false} // Proof of Delivery
		]
		options.forEach(options => {
			if(props.contact?.fieldData?.["contact__CONTACTS_INFO__contactID::delivery_options"].includes(options.label)) {
				options.checked = true
			}
		})

		return options
	})

	const groupedRoles = computed(() => {
		if(
			props?.contact?.portalData?.portal_phone_numbers &&
			props?.contact?.portalData?.portal_emails) {
			return [
				// phone
				{subheading: localesStore.getLabel('561')},
				...props.contact.portalData.portal_phone_numbers,
				// email
				{subheading: localesStore.getLabel('110')},
				...props.contact.portalData.portal_emails
			]
		}
		else {
			return []
		}
	})
</script>